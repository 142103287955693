var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters"},[_c('div',{staticClass:"filters__container"},[_c('UniversalSelect',{staticStyle:{"width":"180px"},attrs:{"options":_vm.appsOptions,"placeholder":"App","title":"App","showSearch":true,"disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeAppFilter},model:{value:(_vm.filters.app),callback:function ($$v) {_vm.$set(_vm.filters, "app", $$v)},expression:"filters.app"}}),_c('UniversalSelect',{staticStyle:{"width":"150px"},attrs:{"options":_vm.languageOptions,"placeholder":"Language","title":"Language","showSearch":true,"disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeLanguageFilter},model:{value:(_vm.filters.language),callback:function ($$v) {_vm.$set(_vm.filters, "language", $$v)},expression:"filters.language"}}),_c('UniversalSelect',{staticStyle:{"width":"180px"},attrs:{"options":_vm.segmentOptions,"placeholder":"Segment","title":"Segment","showSearch":true,"disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeSegmentFilter},model:{value:(_vm.filters.segment),callback:function ($$v) {_vm.$set(_vm.filters, "segment", $$v)},expression:"filters.segment"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Start date")]),_c('a-date-picker',{attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{
          defaultValue: _vm.$moment('00:00:00', 'HH:mm'),
          format: 'HH:mm'
        },"type":"date","size":"large","placeholder":"Start date","disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeStartDate},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("End date")]),_c('a-date-picker',{attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{
          defaultValue: _vm.$moment('00:00:00', 'HH:mm'),
          format: 'HH:mm'
        },"type":"date","size":"large","placeholder":"End date","disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeEndDate},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1),_c('UniversalSelect',{staticStyle:{"width":"130px"},attrs:{"options":_vm.statusOptions,"placeholder":"Status","title":"Status","disabled":_vm.loading || _vm.disabled},on:{"change":_vm.changeStatusFilter},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }