<template>
  <div>
    <HeadSection title="Fortune wheel" permissionOfSection="fortune_wheel">
      <template #actions>
        <a-col :span="14" class="d-flex justify-content-end" style="gap: 10px;">
          <a-button
            v-if="weightEditingMode"
            type="danger"
            ghost
            size="large"
            icon="stop"
            :disabled="changeWeighLoading"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="cancelChangeWheelWeight"
          />
          <a-button
            v-if="isHasWheelListItems"
            type="primary"
            size="large"
            class="success-color"
            :loading="changeWeighLoading"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="changeWheelWeight"
          >
            {{ weightEditingMode ? 'Save' : 'Edit' }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :disabled="weightEditingMode"
            style="font-size: 16px; font-weight: 300; border-radius: 2px;"
            @click="createFortuneWheel"
          >
            <a-icon type="plus" /> Add
          </a-button>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer :loading="userLoading" permissionOfSection="fortune_wheel">
      <div class="d-flex flex-wrap mb-3" style="gap: 10px;">
        <MainFortuneWheelFilters
          ref="wheel-filter"
          :loading="loading"
          :disabled="!hasWheelsListData"
          :appsOptions="appsOptions"
          :languageOptions="languageOptions"
          :segmentOptions="segmentOptions"
          :search="search"
          @change="fetchWheelsList"
        />
        <div
          v-if="isFiltered"
          style="width: fit-content; flex: 1 1 auto;"
          class="d-flex justify-content-end align-items-end"
        >
          <a-button
            type="primary"
            size="large"
            @click="resetAllFilters"
          >
            Reset filters
          </a-button>
        </div>
      </div>
      <a-table
        class="fortune-wheel-table"
        :class="{'fortune-wheel-table__edit-mode' : weightEditingMode}"
        :dataSource="wheelsListData"
        :pagination="wheelsListMeta"
        :row-key="(record) => record.id"
        :customRow="customRow"
        :columns="fortuneWheelColumns"
        :loading="loading"
        @change="changePagination"
      >
        <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="c => (searchInput = c)"
            style="width: 188px; margin-bottom: 8px; display: block;"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys)"
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            :disabled="!selectedKeys[0]"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            :disabled="!selectedKeys[0] && !search.length"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          type="search"
          :style="{ color: search.length ? '#108ee9' : '#8c8c8c' }"
        />
        <template slot="name" slot-scope="name, record">
          <router-link class="text-primary" :to="`/fortune-wheel/${record.id}`">{{ name }}</router-link>
        </template>
        <template slot="segment" slot-scope="segment">
          {{ segment ? segment : '—' }}
        </template>
        <template slot="starts_at" slot-scope="starts_at">
          {{ starts_at ? starts_at : '—' }}
        </template>
        <template slot="ends_at" slot-scope="ends_at">
          {{ ends_at ? ends_at : '—' }}
        </template>
        <template slot="weight" slot-scope="weight, record">
          <div class="change-weight d-flex align-items-center">
            <a-input-number
              v-if="editPermission"
              v-model="record.weight"
              :disabled="!weightEditingMode || changeWeighLoading"
              type="number"
              :max="30000"
              class="transparent-input"
              :class="weightEditingMode ? 'transparent-input__focus' : 'transparent-input__hover-pointer'"
              :loading="changeWeighLoading"
            />
            <span v-else>{{ record.weight }}</span>
          </div>
        </template>
        <template slot="status" slot-scope="status">
          <div class="d-flex align-items-center justify-content-end">
            <span
              style="text-transform: capitalize;"
              class="d-flex mr-2"
              :class="status === 'active' ? '' : 'text-default'"
            >
              {{ status }}
            </span>
          </div>
        </template>
      </a-table>
    </MainContainer>
  </div>
</template>

<script>
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import apiClient from '@/services/axios'
import MainFortuneWheelFilters from '@/views/apps/fortune-wheel/components/MainFortuneWheelFilters.vue'
import { mapState } from 'vuex'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    editPermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    MainFortuneWheelFilters,
  },
  data: () => ({
    fortuneWheelColumns: [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '17%',
        ellipsis: true,
        scopedSlots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'name',
        },
        onFilter: (value, record) =>
          record.name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      },
      {
        title: 'Segment',
        dataIndex: 'segment',
        width: '15%',
        scopedSlots: { customRender: 'segment' },
      },
      {
        title: 'Start date',
        dataIndex: 'starts_at',
        width: '15%',
        scopedSlots: { customRender: 'starts_at' },
      },
      {
        title: 'End date',
        dataIndex: 'ends_at',
        width: '15%',
        scopedSlots: { customRender: 'ends_at' },
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        width: '10%',
        scopedSlots: { customRender: 'weight' },
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'right',
        width: '20%',
        scopedSlots: { customRender: 'status' },
        sorter: true,
      },
    ],
    wheelsListData: [],
    wheelsListMeta: {
      page: 1,
      per_page: 10,
      total: 0,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40'],
    },
    wheelWeightStateSnapshot: [],
    weightEditingMode: false,
    changeWeighLoading: false,
    loading: false,
    appsOptions: [],
    languageOptions: [],
    segmentOptions: [],
    search: '',
    isFiltered: false,
  }),
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    isHasWheelListItems() {
      return !!this.wheelsListData?.length
    },
    hasWheelsListData() {
      return this.isHasWheelListItems || this.isFiltered
    },
  },
  methods: {
    customRow(wheel) {
      return {
        on: {
          click: (e) => {
            if (this.weightEditingMode) {
              e.preventDefault()
              return false
            }
            this.$router.push(`/fortune-wheel/${wheel.id}`)
          },
        },
      }
    },
    changeWheelWeight() {
      if (this.weightEditingMode) {
        this.saveWheelWeight()
      } else {
        this.weightEditingMode = true
      }
    },
    cancelChangeWheelWeight(update = true) {
      if (update) {
        this.wheelWeightStateSnapshot.forEach((el, index) => {
          this.wheelsListData[index].weight = el.position
        })
      }
      this.weightEditingMode = false
    },
    async saveWheelWeight() {
      try {
        this.changeWeighLoading = true
        const data = this.wheelsListData.map(wheel => {
          return {
            id: wheel.id,
            position: wheel.weight,
          }
        })
        const url = `${wheelApiUrl}/admin/wheels/batch`
        const response = await apiClient.post(url, data)
        if (response?.data?.success) {
          this.setWheelWeightStateSnapshot(this.wheelsListData)
          this.cancelChangeWheelWeight(false)
          this.$notification.success({
            message: response?.data?.message || 'Successfully',
          })
        }
      } catch (e) {
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.changeWeighLoading = false
      }
    },
    createFortuneWheel() {
      this.$router.push('/fortune-wheel/create')
    },
    handleSearch(selectedKeys) {
      this.search = selectedKeys[0]
      const filters = this.$refs['wheel-filter'].getFilters()
      this.fetchWheelsList({ ...filters, name: this.search })
    },
    handleReset(clearFilters) {
      clearFilters()
      this.search = ''
      const filters = this.$refs['wheel-filter'].getFilters()
      this.fetchWheelsList({ ...filters, name: this.search })
    },
    resetAllFilters() {
      this.search = ''
      this.$refs['wheel-filter'].resetFilters()
      this.fetchWheelsList()
    },
    changePagination(pagination, _, sorter) {
      this.cancelChangeWheelWeight()
      const filters = {
        ...this.$refs['wheel-filter'].getFilters(),
        page: pagination.current,
        limit: pagination.pageSize,
      }
      if (sorter && sorter?.order) {
        filters.sort_by = sorter.field
        filters.sort_direction = this.getSortDirectionParam(sorter.order)
      }
      this.fetchWheelsList(filters)
    },
    getSortDirectionParam(order) {
      if (order === 'ascend') {
        return 'asc'
      }
      return 'desc'
    },
    getTableMeta(meta) {
      return {
        page: meta?.current_page || 1,
        perPage: meta?.per_page || 10,
        total: meta?.total || 0,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '40'],
      }
    },
    checkFilters(filters) {
      if (filters === null) {
        return false
      }
      return !!filters?.applications || !!filters?.languages || (filters?.segment ? filters.segment : filters?.segment === 0) || !!filters?.starts_at || !!filters?.ends_at || !!filters?.status || this.search.length
    },
    setWheelWeightStateSnapshot(data) {
      this.wheelWeightStateSnapshot = data.map(el => {
        return {
          id: el.id,
          position: el.weight,
        }
      })
    },
    async fetchWheelsList(filters = null) {
      this.loading = true
      if (this.checkFilters(filters)) {
        this.isFiltered = true
      } else {
        this.isFiltered = false
      }
      const url = `${wheelApiUrl}/admin/wheels`
      try {
        const response = await apiClient.get(url, { params: filters })
        if (response?.data?.success) {
          this.wheelsListData = response?.data?.data || []
          this.setWheelWeightStateSnapshot(this.wheelsListData)
          this.wheelsListMeta = this.getTableMeta(response?.data?.meta)

          if (response?.data?.filters) {
            this.appsOptions = response?.data?.filters?.applications.map(application => {
              return { name: application.name, value: application.id, platform: application.platform }
            })
            this.languageOptions = response?.data?.filters?.languages.map(language => {
              return { name: language.name, value: language.id }
            })
            this.segmentOptions = response?.data?.filters?.segments.map(segment => {
              return { name: segment.name, value: segment.id }
            })
          }
        }
      } catch (e) {
        console.log('Error when trying to get wheels of fortune', e)
        this.$notification.error({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async getQueryFilters() {
      const filters = {}
      for (const [key, value] of Object.entries(this.$route.query)) {
        if (value) {
          switch (key) {
            case 'app':
              filters['applications'] = value
              break
            case 'language':
              filters['languages'] = value
              break
            case 'segment':
              filters['segment'] = value
              break
            case 'startDate':
              filters['starts_at'] = value
              break
            case 'endDate':
              filters['ends_at'] = value
              break
            case 'status':
              filters['status'] = value
              break
          }
        }
      }

      await this.fetchWheelsList(filters)

      if (this.$refs['wheel-filter']) {
        this.$refs['wheel-filter'].setFilters(this.$route.query)
      } else {
        setTimeout(() => {
          this.$refs['wheel-filter'].setFilters(this.$route.query)
        }, 500)
      }
    },
  },
  async created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.fetchWheelsList()
    } else {
      this.getQueryFilters()
    }
    window.addEventListener('popstate', this.getQueryFilters)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.getQueryFilters)
  },
}
</script>

<style lang="scss">
.fortune-wheel-table {
  .ant-table-row {
    cursor: pointer;
  }
  &__edit-mode {
    .ant-table-row {
      cursor: default;
    }
  }
}
[data-kit-theme="default"] .ant-table-empty .ant-table-body {
  overflow: visible !important;
}
.ant-table-column-has-actions {
  overflow: visible !important;
}
</style>
